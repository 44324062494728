<template>
  <div class="product">
    <div class="title">{{ categories }}</div>
  </div>
</template>

<script>

export default {
  name: "packaging",
  data() {
    return {
      categories: "",
      productList: [],
    }
  },
  mounted() {
    // console.log("mounted")
    this.categories = this.$route.query.categories;
    this.getPackagingList();
  },
  // onload(){
  //   this.categories = this.$route.query.categories;
  //   this.getProductList();
  // },
  methods: {
    getPackagingList() {
      console.log(this.categories);
    }
  },
  watch: {
    '$route'(val, from) {
      if (val.query.categories) {
        this.categories = val.query.categories
        this.getProductList()
      }

    }
  }
}
</script>

<style scoped>
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 350px;
}

.el-card {
  border: 0px
}

.title {
  padding-top: 20px;
  text-align: center;
  font-size: 30px;
  color: #505862;
}

</style>
