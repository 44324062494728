<template>
  <div style="padding-top:30px ">
    <div class="top">We are more than happy to hear from you!</div>
    <el-row>
      <el-col :span="5" :offset="3">

        <h3>Telephone</h3>
        <p>{{ this.$store.state.baseInfo.baseInfo.phone }}</p>
        <h3>Email</h3>
        <p>{{ this.$store.state.baseInfo.baseInfo.mail }}</p>
        <h3>Address</h3>
        <p>{{ this.$store.state.baseInfo.baseInfo.address }}</p>
        <p>
          <a :href="'mailto:'+this.$store.state.baseInfo.baseInfo.mail" ><el-button circle icon="iconfont icon-email-fill"></el-button></a>
          <a href="skype:live:849951157?chat"  class="skype"><el-button circle icon="iconfont icon-skype" ></el-button></a>
        </p>
      </el-col>
      <el-col :span="10" :offset="2">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="top">
          <el-form-item label="Name" prop="name">
            <el-input clearable v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="mail">
            <el-input clearable v-model="form.mail"></el-input>
          </el-form-item>
          <el-form-item label="Message" prop="message">
            <el-input autosize type="textarea" :autosize="{ minRows: 2}" v-model="form.message"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="background-color: #74a938;color: white" @click="onSubmit">Submit</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import {Message} from "element-ui";
import  "@/assets/iconfont/iconfont.css"
import  "@/assets/iconfont/iconfont.js"

export default {
  name: "contact",
  data() {
        return {
      form: {
        name: "",
        mail: "",
        message: ""
      },
      rules: {
        name: [{required: true, message: "please type your name", trigger: "blur"}],
        mail: [{required: true, message: "please type your email", trigger: "blur"}],
        message: [{required: true, message: "please type your message", trigger: "blur"}],
      }
    }
  },
  methods: {
    onSubmit() {
      // Message({
      //   message: '1',
      //   type: 'success',
      //   duration: 5 * 1000
      // })

      // console.log(this.$refs.form.validate)
      this.$refs.form.validate((valid) => {
        // console.log(valid)
        if (valid) {
          // console.log("123")
          axios.post(this.api.baseUrl + '/open/api/contact',this.form).then(res=> {

            if (res.data.code === 200) {
              Message({
                message: 'Success',
                type: 'success',
                duration: 5 * 1000
              })
            }else{
              Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
              })
            }
            // console.log(res)
          })
        } else {
          // console.log('error submit!!');
          return false;
        }


        // console.log('submit!');

      })
    }
  }
}
</script>
<style>
/*.el-message .el-message--success {*/
/*  top: 100px !important;*/
/*}*/
</style>

<style scoped>
.top {
  background-color: rgba(211, 211, 211, 0.44);
  line-height: 60px;
  margin: 0 80px;
  font-style: oblique;
  font-size: 20px;
  padding-left: 20px;
  color: gray;
}
a{
  padding-right: 20px;
}
</style>
