<template>
<div >
  <banner></banner>
  <categories ></categories>
</div>
</template>

<script>
// import axios from "axios"
import banner from "@/pages/custom/banner";
import categories from "@/pages/custom/categories";

export default {
  name: "cusHome",
    components: {  banner, categories},
  mounted() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>
