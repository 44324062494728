<template>
  <div>
    <el-row :gutter="20" style="margin: 0">
      <el-col :xs="24" :sm="13" :md="13" :lg="13" :xl="13">
        <div class="aboutInfo" ref="aboutInfo"></div>
        <!--        <p>With over 10 years experience in branded promotional gifts, we work with more than 200 professional-->
        <!--          manufactures, we are confident to provide you high quality products at competitive prices, but never discount-->
        <!--          on the best service.-->
        <!--          We export products to customers all around the world, UK, USA, France, Germany, Italy, Spain…from the very-->
        <!--          first inquiry, we believe each of our customers, no matter how large or small, form the bedrock of our-->
        <!--          business and as such we treat each and every one of them like gold dust.-->
        <!--        </p>-->
        <!--        <ul>-->
        <!--          <li>We focus on our customers’true demands rather than just making the sale.</li>-->
        <!--          <li>We believe the success of the products we supply to our customers build long-term customer relationship.-->
        <!--          </li>-->
        <!--          <li>We guarantee our products and service. We say what we do, we do what we say.</li>-->
        <!--        </ul>-->
      </el-col>
      <el-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11">
        <div class="aboutpics">
        <el-row style="height: 50%">
          <!--          <img src="../../assets/ab1.jpg"></el-row>-->
          <el-image fit="cover" ref="img1" :src="aboutPicList[0]"></el-image>
        </el-row>
          <el-row style="height: 50%" :gutter="20">
            <el-col :span="9" >
              <!--            <img src="../../assets/ab2.jpg"></el-col>-->
              <el-image fit="cover" ref="img2" :src=" aboutPicList[1]"></el-image>
<!--              <img ref="img2"/>-->
            </el-col>
              <el-col :span="15" >
                <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="padding: 0">-->
                <!--            <img src="../../assets/ab3.jpg"></el-col>-->
<!--                <img ref="img3"/>-->
                <el-image fit="cover" ref="img3" :src=" aboutPicList[2]"></el-image>
              </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "about",
  data(){
    return{
      aboutPicList:[]
    }
  },
  mounted() {
    this.$nextTick(()=> this.getAboutInfo()
    )
    // this.getAboutInfo()
  }
  ,
  methods: {
    getAboutInfo() {
      axios.get(this.api.baseUrl + '/open/api/about').then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data.aboutpics)
          this.$refs.aboutInfo.innerHTML = res.data.data.aboutinfo
          this.aboutPicList = res.data.data.aboutpics.split(',')
          // console.log(this.aboutPicList[1])
        } else {
          throw 'err'
        }
      })
    }

  }
}
</script>
<style>

p {
  line-height: 30px;
}

li {
  padding: 0;
  margin: 0;
  line-height: 30px;
  /*color: grey;*/
}

ul {
  padding: 0;
}
</style>
<style scoped>

.aboutInfo{
  font-size: 1.2rem;
  padding: 0 10px 0 40px;
}
.aboutpics{
  padding-top: 30px;
  padding-right: 10px;
}
/*img {*/
/*  !*max-width: 100%;*!*/
/*  max-height: 30vh;*/
/*  !*height: 30vh;*!*/
/*  padding: 10px 15px;*/
/*  object-fit: fill;*/
/*}*/

p {
  line-height: 30px;
}

li {
  padding: 0;
  margin: 0;
  line-height: 30px;
  /*color: grey;*/
}

ul {
  padding: 0;
}
.el-image{
  /*height: 50%;*/
  /*margin-top: 100px;*/
  /*padding-top: 10px;*/
}
</style>
