<template>
  <div class="center">
    <!--    <div class="title">{{ name }}</div>-->
    <el-row>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <!--        <div style="transform: scale(0.5)">-->
        <div
            :style="{transform : `scale(${this.$store.state.mtype.mtype==='mobile'?1:0.8})`,transformOrigin: '50% 20% 0'}">
          <div>
            <el-image :src="mainImgUrl" class="mainpic" fit="contain" :preview-src-list="imgUrlList">
            </el-image>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
            <i style="font-size: 30px;display: inline-block;cursor: pointer;"
               class="el-icon-arrow-left" @click="imgLeft()"></i>
            <ul class="Img_ul">
              <li v-for="(item,index) in imgUrlList" :key="index" class="Img_li" :style="imgStyle"
                  @click="changeImg(item, index)">
                <img :class="index === imgActiveIndex ? 'img_activeBorder' : ''" :src="item"
                     style="width:50px;height:50px">
              </li>
            </ul>
            <i style="font-size: 30px;display: inline-block;  cursor: pointer;"
               class="el-icon-arrow-right" @click="imgRight()"></i>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="position: sticky;top: 20%">
        <div :style="{transform : `scale(${this.$store.state.mtype.mtype==='mobile'?1:0.9})`}">
          <div class="title">Specificaties</div>

          <el-descriptions title="" class="describe" :column="1">
            <el-descriptions-item v-for="(item,index) in specificaties" :key="index" :label="item.title"
                                  label-class-name="my-label" content-class-name="my-content">
              {{ item.value }}
            </el-descriptions-item>
          </el-descriptions>
        </div>


      </el-col>
    </el-row>
  </div>
</template>

<script>
// import LocalElCarouselItem from "@/components/localcarousel/item";
import axios from "axios";

export default {
  name: "goodDetail",
  // components: {LocalElCarouselItem},
  data() {
    return {
      name: "",
      mainImgUrl: "",
      imgUrlList: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg'
      ],
      imgActiveIndex: 0, // 当前移动图片的索引值
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      specificaties: [{'order': 1, 'title': 'Product Code', 'value': '12'}, {
        'order': 2,
        'title': 'Max Imprint Area',
        'value': ''
      }, {'order': 3, 'title': 'Country Of Origin', 'value': ''}]
    }
  },


  mounted() {
    // console.log("mounted")
    // console.log('route', this.$route)
    this.$nextTick(() => {
      this.init(this.$route.query.id)
    })

    this.toTop()
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
      }
    }
  },

  methods: {
    init(goodId) {

      // const baseurl = this.$store.state.baseInfo.baseInfo.domain
      // console.log(baseurl)
      // var aa = this.$route.query.pics.split(",")
      // this.imgUrlList = []
      // for (var i = 0; i < aa.length; i++) {
      //   this.imgUrlList.push(baseurl + aa[i])
      // }
      axios.get(this.api.baseUrl + '/open/api/good/' + goodId).then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data.aboutpics)
          // this.mainImgUrl =this.$store.state.baseInfo.baseInfo.domain + res.data.data.pic
          this.mainImgUrl = res.data.data.pic
          this.name = res.data.data.name
          this.imgUrlList = res.data.data.pics.split(",")
          this.imgUrlList.unshift(this.mainImgUrl)
          // this.imgUrlList.forEach(x => this.$store.state.baseInfo.baseInfo.domain + x)
          // const pics = res.data.data.pics.split(",")
          // this.imgUrlList = []
          // this.imgUrlList.push(this.mainImgUrl)
          // for (var i = 0; i < pics.length; i++) {
          //   this.imgUrlList.push( this.$store.state.baseInfo.baseInfo.domain + pics[i])
          // }
          this.specificaties = JSON.parse(res.data.data.specificaties)
          // console.log(this.aboutPicList[1])
        } else {
          throw 'err'
        }
      })

    // this.imgUrlList = this.$route.query.pics
    // this.imgUrlList.unshift(this.$route.query.pic)
    // this.mainImgUrl = this.$route.query.pic
    // this.name = this.$route.query.name
    // // this.imgUrlList.forEach(x => this.$store.state.baseInfo.baseInfo.domain+  + x)
    // this.specificaties = JSON.parse(this.$route.query.specificaties)


  },
  addItem() {
    this.testList.push({'order': this.testList.length + 1})
    // console.log(this.testList)
  },
  delItem(index, row) {
    // console.log(this.testList,index, row,this.testList[index])
    this.testList.splice(index, 1)
  },

  toTop() {
    // document.documentElement.scrollTop = 0;
  },
  changeImg(item, index) {
    this.mainImgUrl = item
    this.imgActiveIndex = index
  },
  imgLeft() {
    if (this.imgActiveIndex > 0) {
      this.imgActiveIndex--  // 索引值-1
      this.imgUrlList.forEach((item, index) => { // 循环小图,通过判断索引值赋值给大图
        if (this.imgActiveIndex === index) {
          this.mainImgUrl = item
        }
      })
    }
    if (this.imgActiveIndex >= 4) {
      var index = 0
      const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
        if (index < 33) { // 移动次数(33次)
          this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
          index++
          return
        } else {
          window.clearInterval(temp) // 移动完清除定时器
        }
      }, 10)
    }
  },
  imgRight() {
    if (this.imgActiveIndex < this.imgUrlList.length - 1) {
      this.imgActiveIndex++
      this.imgUrlList.forEach((item, index) => {
        if (this.imgActiveIndex === index) {
          this.mainImgUrl = item
        }
      })
      if (this.imgActiveIndex >= 5) {
        this.allDistance = -66 * (this.imgActiveIndex - 4)
        var index = 0
        const temp = window.setInterval(() => {
          if (index < 33) {
            this.imgDistance -= 2 // 每次向右移动的距离
            index++
            return
          } else {
            window.clearInterval(temp)
          }
        }, 10)
      }
    } else if (this.imgActiveIndex === this.imgUrlList.length - 1) { // 到达最后一张图片，再点击跳转回第一张
      this.imgActiveIndex = 0;
      this.mainImgUrl = this.imgUrlList[0]
      var index = 0
      const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
        if (index < Math.abs(this.allDistance / 2)) { // 取绝对值再除
          this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
          index++
          return
        } else {
          window.clearInterval(temp) // 移动完清除定时器
        }
      }, 1)
    }
  }
}
,
watch: {
  '$route'(val, from)
  {
    this.init(this.$route.query.id)
    // this.toTop()


  }
}
}
</script>
<style>
.my-label {
  font-size: 1.3vw;
  /*color: black;*/
  width: 50%;

}

.my-content {
  font-size: 1.2vw;
  color: darkgray;

}

.el-descriptions-item {
  border-bottom: lightgrey 1px solid;
}

</style>
<style scoped>
.mainpic {
  /*border: black solid 0.5px;*/
  max-width: 100%;
  height: 80vh;
}

.center {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.title {
  padding-top: 20px;
  text-align: center;
  font-size: 1.4rem;
  /*color: #505862;*/
  padding-bottom: 20px;
}

.describe {
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 1.4rem;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item {
  border: black 1px solid;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  background-image: url("http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg");
  /*background-repeat: no-repeat;*/
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
  background-image: url("http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png");
}

img {
  object-fit: contain;
}

.Img_ul {
  padding: 0;
  position: relative;
  display: flex;
  /*left: 36px;*/
  width: 330px;
  /*height: 50px;*/
  overflow: hidden;
  list-style: none;
}

.Img_li {
  float: left;
  margin: 0 8px;
  cursor: pointer;
}

.img_activeBorder {
  border: 3px solid #0dcc73;
}

@media screen and (max-width: 768px) {
  .describe {
    width: 100vw;
    transform: scale(0.9);
  }

  /deep/ .mainpic {
    max-width: 100%;
    max-height: 50vh;
  }

  /deep/ .my-label {
    font-size: 1.2rem !important;
    /*color: black;*/
    width: 50%;

  }

  /deep/ .my-content {
    font-size: 1.1rem !important;
    color: darkgray;

  }
}
</style>
