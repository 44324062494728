<template>
  <div class="product" ref="products">
    <div class="title">{{ categories }}</div>
    <el-row :gutter="20" style="width: 80%">
      <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6" v-for="(item, index) in productList" :key="index">
        <!--        <div @mouseenter="changeImageSrc( index,'hover',item.hoverimg)"-->
        <!--             @mouseleave="changeImageSrc( index,'',item.img)">-->
        <div @click="toProduct(item)">
          <div class="dimg">
            <img class="image" :src="item.pic"></div>
          <div class="bottag">
            {{ item.name }}
            <!--              <span>{{ item.title }}</span>-->
            <!--              <div class="bottom clearfix">-->
            <!--                <el-button  class="button" @click="toProduct(item.title)">{{ item.title }}</el-button>-->
            <!--              </div>-->
          </div>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "cusProduct",
  data() {
    return {
      categories: "",
      productList: [
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },

      ]
    }
  },
  mounted() {

    // console.log(this.$store.state.menu.menu)
    // console.log("mounted",document.documentElement.scrollTop)

    if (this.$route.query.categories) {
      this.categories = this.$route.query.categories;
    } else {
      this.categories = this.$route.name.toLocaleUpperCase()
    }
    this.$nextTick(()=>{this.getProductList() })

    this.getTerminalType();

    this.toTop()

  },
  // onload(){
  //   this.categories = this.$route.query.categories;
  //   this.getProductList();
  // },
  methods: {
    getTerminalType() {
      if(this.$store.state.mtype.mtype==='mobile'){
        this.$nextTick(() => {
              this.$refs.products.childNodes[1].style.width='100%'
              // console.log()
            })
      }
      // console.log(navigator.userAgent)
      // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //   this.$nextTick(() => {
      //     this.$refs.products.childNodes[1].style.width='100%'
      //     // console.log()
      //   })
      //   return 'mobile';
      // } else if (/(Android)/i.test(navigator.userAgent)) {
      //   this.$nextTick(() => {
      //     this.$refs.products.childNodes[1].style.width='100%'
      //     // console.log()
      //   })
      //
      //   return 'mobile';
      // } else {
      //
      //   return 'pc';
      // }
    },
    getProductList() {
      // console.log(this.categories);
      axios.get(this.api.baseUrl + '/open/api/classify/' + this.categories).then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data.aboutpics)
          this.productList = res.data.data
          // console.log(this.aboutPicList[1])
        } else {
          throw 'err'
        }
      })

    },
    toProduct(good) {
      // const pics = good.pics.split(",")
      // var imgUrlList = []
      // for (var i = 0; i < pics.length; i++) {
      //   imgUrlList.push( this.$store.state.baseInfo.baseInfo.domain + pics[i])
      // }



      // this.$router.push({path: '/goods', query: {name:good.name,pic:good.pic, pics:imgUrlList,specificaties:good.specificaties}})
      this.$router.push({path: '/goods', query: {id:good.id}})

      // const a = this.$router.resolve({path: '/goods', query: {name:good.name,pic:good.pic, pics:imgUrlList,specificaties:good.specificaties}})
      // window.open(a.href,'_blank')
    },
    changeImageSrc(e) {
      // console.log(e)
      // let tempStr = way === 'hover' ? 'Hover' : ''
      // console.log(tempStr)
      this.productList[e].show = !this.productList[e].show
    },
    toTop() {
      // document.documentElement.scrollTop = -1000;
    },
  },
  watch: {
    '$route'(val, from) {
      // console.log(val, from)
      if (val.query.categories) {
        this.categories = val.query.categories
        this.getProductList()
      } else {
        this.categories = val.name.toLocaleUpperCase()
        this.getProductList()
      }
      // if (from.name !== val.name){
      //   this.toTop()
      // }
      this.getTerminalType()
      this.toTop()

    }
  }
}
</script>

<style scoped>
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 350px;
}

.el-card {
  display: flex;
  align-items: end;
  justify-content: center;
  border: 0px;
  transition: all 0.4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  background: var(--backimg);
  /*background: url('http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png');*/
  background-repeat: no-repeat; /* 不会重复 */
  background-position: center; /* 是为了让图片放大的时候从中部放大，不会偏移 */
  /*background-size: 100% 100%;*/
  background-size: contain;
  /*padding-top: 100%;*/
  height: calc(100vw / 4 - 100px);
  /*height: 100px;*/
}

.title {
  padding-top: 20px;
  text-align: center;
  font-size: 30px;
  color: #505862;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.dimg {
  display: flex;
  overflow: hidden;
  /*height:15rem;*/
  /*width: 15rem;*/
  /*min-height: 15vw;*/
  /*max-width: 20vw;*/
  /*width: 20rem;*/
  /*max-height: 20rem;*/
  /*min-height: 20vh;*/
  aspect-ratio: 1;
  /*min-height: 12rem;*/
  align-items: center;
  justify-content: center;
}

.image {
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s linear 0s;
  max-width: 80%;
  max-height: 80%;
  /*display: block;*/
  transform: scale(1.0);
}


.image:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);

  /*transform: scale(1.2);*/
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.el-card:hover {
  /*padding-top: 110%;*/
  /*background-size: contain;*/
  background-size: calc(100vw / 4 - 90px);
}

.bottag {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  color: white;
  height: 100%;
  background-color: #74a938;
  /*margin: 20px;*/
  /*width: calc(100vw / 4 - 100px);*/
}

</style>
