import Vue from 'vue'
import VueRouter from 'vue-router'
import manage from "@/pages/manage";
import home from "@/pages/home"
import cusHome from "@/pages/custom/home"
import product from "@/pages/custom/product";
import about from "@/pages/custom/about";
import packaging from "@/pages/custom/packaging";
import accessories from "@/pages/custom/accessories";
import contact from "@/pages/custom/contact";
import goodDetail from "@/pages/custom/goodDetail";
import store from "@/store";

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// //添加以下代码
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode: 'history',
    //4.配置路由的path和组件
    routes: [
        {
            path: '/',
            name: 'main',
            component: home,
            redirect: '/home',
            children: [
                // {
                //     path: '/',
                //     name: 'cusHome',
                //     component: cusHome
                // },
                // {
                //     path: 'product',
                //     name: 'product',
                //     component: product
                // },
                // {
                //     path: '/about',
                //     name: 'about',
                //     component: about
                // },
                // {
                //     path: '/packaging',
                //     name: 'packaging',
                //     component: product
                // }
                // ,
                // {
                //     path: '/contact',
                //     name: 'contact',
                //     component: contact
                // }
                // ,
                // {
                //     path: '/accessories',
                //     name: 'accessories',
                //     component: product
                // },
                {
                    path: '/goods',
                    name: 'goods',
                    component: goodDetail
                }

            ]
        },
        // {
        //     path: '/home',
        //     name: 'home',
        //     redirect: '/',
        //     component: home
        // },
        // {
        //     path: '/manage',
        //     name: 'manage',
        //     component: manage
        // },

        // {
        //     path: '/home',
        //     name: 'home',
        //     component: home
        // },
    ],

    scrollBehavior(to, from, savedPosition) {
        // to.meta.savedPosition = savedPosition;
        // console.log('saveposition',to, from,savedPosition)
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve({ x: savedPosition.x, y: savedPosition.y })
                } else {
                    resolve({ x: 0, y: to.meta.top || 0 })
                }
            }, 300)
        })
    }

})


//5.导入路由实例
export default router
router.beforeEach((to, from, next) => {
    // console.log(store.state.menu.menu.length,'asdfadf')
    if (store.state.menu.menu === null || store.state.menu.menu.length === 0){
        store.dispatch('GetMenu').then( x => {
            // console.log(store.state.menu.menu.length,'asdfadf')
            // router.addRoutes(x)
            // console.log("xxxxxxxxxxx",x)
            x.forEach(x => {
                router.addRoute('main', x)
            })
            next({ ...to, replace: true })
        })
        // next({path:'/index'});
        // next({ ...to, replace: true })
        // console.log(router)
    }else{
        next()
    }



})
