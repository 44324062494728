<template>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <div>
<!--    <router-link :to="{path:'/manage'}">dianjiwo</router-link>-->
<!--      <datapage/>-->
    <router-view></router-view>
<!--  <router-link to="/test">test</router-link>-->
  </div>

</template>

<script>
// import datapage from './pages/home.vue'
export default {
  name: 'App',
  // mounted() {
  //   this.$nextTick(() => this.getMenus())
  // }

  // components: {
  //   datapage,
  // },
  methods:{
    turnmanage(){
      this.$router.push("/manage")
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0 0 0 0;
  color: #505862;
}
</style>
