<template>
  <div class="categories">
    <div class="title">BROWSE CATEGORIES</div>
    <el-row :gutter="20" style="width: 80%">
<!--      <el-col :span="6" v-for="(item, index) in categoriesList" :key="index">-->
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-for="(item, index) in categoriesList" :key="index">
        <!--        <div @mouseenter="changeImageSrc( index,'hover',item.hoverimg)"-->
        <!--             @mouseleave="changeImageSrc( index,'',item.img)">-->
        <div @click="toProduct(item.name)"  v-on:mouseenter="visible(index)" @mouseleave="invisible(index)" ref="bottag">
<!--        <div @click="toProduct(item.name)" >-->
          <div  class="dimg">
          <img class="image" :src="item.pic"></div>
<!--          <el-card :body-style="{ padding: '10px' ,height: 'auto'}" shadow="never"-->
<!--                   :style="{'&#45;&#45;backimg' :'url('+item.img+')'}" >-->
<!--            &lt;!&ndash;            <div class="image1" sty></div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <img :src="item.img"&ndash;&gt;-->
<!--            &lt;!&ndash;                 class="image1" alt="" @click="toProduct(item.title)">&ndash;&gt;-->
<!--            &lt;!&ndash;            <img :src="item.hoverimg"&ndash;&gt;-->
<!--            &lt;!&ndash;                 class="image" alt="" v-show="item.show">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="bottag" >&ndash;&gt;-->
<!--&lt;!&ndash;              {{ item.title }}&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash;              <span>{{ item.title }}</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash;              <div class="bottom clearfix">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash;                <el-button  class="button" @click="toProduct(item.title)">{{ item.title }}</el-button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </el-card>-->
          <div class="bottag"  >
            {{ item.name }}
            <!--              <span>{{ item.title }}</span>-->
            <!--              <div class="bottom clearfix">-->
            <!--                <el-button  class="button" @click="toProduct(item.title)">{{ item.title }}</el-button>-->
            <!--              </div>-->
          </div>

        </div>

      </el-col>
    </el-row>
<!--        <img src="file:/C:/Users/liang/Desktop/loginBg.jpg"  class="image1">-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "categories",
  data() {
    return {

      categoriesList: [
        {
          title: "BT-U080",
          img: "http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },
        {
          title: "BT-U080",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png"
          , show: false,
        },
        {
          title: "BT-U074",
          img: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png",
          describe: "",
          hoverimg: "http://www.biogiftech.com/uploadfile/2020/1130/20201130052605410.jpg"
          , show: false,
        },

      ]
    }

  },
  mounted() {
    // console.log(this.$store.state.menu)
    this.$nextTick(()=>{

      // this.categoriesList = this.$store.state.menu.products
      // this.categoriesList.forEach(x => x.title = x.name)
      this.getCategories()
    })

    // console.log(this.categoriesList)
  },
  methods: {
    visible(index){
      document.getElementsByClassName('bottag')[index].style.transform='scale(1)'
      document.getElementsByClassName('bottag')[index].style.backgroundColor='#74a938'
      document.getElementsByClassName('bottag')[index].style.color='white'
    },
    invisible(index){
      document.getElementsByClassName('bottag')[index].style.transform='scale(0.9)'
      document.getElementsByClassName('bottag')[index].style.backgroundColor=''
      // document.getElementsByClassName('bottag')[index].style.color='black'
    },
    getCategories(){
      axios.get(this.api.baseUrl + '/open/api/classify/' + 'Products').then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data.aboutpics)
         this.categoriesList = res.data.data
          // this.categoriesList.forEach(x=>x.pic = this.$store.state.baseInfo.baseInfo.domain+ x.pic)
          // console.log(this.aboutPicList[1])
        } else {
          throw 'err'
        }
      })
    },
    toProduct(categories) {
      // this.$router.push(`/product?categories=${categories}`)
      this.$router.push({path: '/product', query: {categories: categories}})
      // this.$route.push(`/products?categories=${categories}`)
      // this.$route.push("/products")
    },
    changeImageSrc(e) {
      // console.log(e)
      // let tempStr = way === 'hover' ? 'Hover' : ''
      // console.log(tempStr)
      this.categoriesList[e].show = !this.categoriesList[e].show
    }
    ,

  }
}
</script>

<style scoped>
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.el-card {
  display: flex;
  align-items: end;
  justify-content: center;
  border: 0px;
  transition: all 0.4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  background: var(--backimg);
  /*background: url('http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png');*/
  background-repeat: no-repeat; /* 不会重复 */
  background-position: center; /* 是为了让图片放大的时候从中部放大，不会偏移 */
  /*background-size: 100% 100%;*/
  background-size: contain;
  /*padding-top: 100%;*/
  height: calc(100vw / 4 - 100px);
  /*height: 100px;*/
}

.title {
  padding-top: 20px;
  text-align: center;
  font-size: 30px;
  color: #505862;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}
.dimg {
  padding-top: 20px;
  display: flex;
  overflow: hidden;
  max-height: 100%;
  /*width: 20vw;*/
  /*height: 20vw;*/
  align-items: center;
  justify-content: center;
}


.image {
  overflow: hidden;
  cursor: pointer;


  transition: all 0.4s linear 0s;
  /*width: 90%;*/
  max-width: 80%;
  max-height: 80%;
  /*display: block;*/
  transform: scale(0.9);
}


.image:hover {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);

  /*transform: scale(1.2);*/
}


.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

/*.test1{*/
/*  width: 200px;*/
/*  height: 200px;*/
/*  background: orange;*/
/*  transition: all 0.4s;*/
/*  -moz-transition:all .4s;*/
/*  -webkit-transition:all .4s;*/
/*  -o-transition:all .4s;*/
/*  background: url('http://www.biogiftech.com/uploadfile/2020/1130/20201130052302681.png');*/
/*  background-repeat:no-repeat;   !* 不会重复 *!*/
/*  background-position: center;   !* 是为了让图片放大的时候从中部放大，不会偏移 *!*/
/*  background-size: 100% 100%;*/
/*}*/
.el-card:hover {
  /*padding-top: 110%;*/
  /*background-size: contain;*/
  background-size: calc(100vw / 4 - 90px);
}

.bottag {
  transition: all 0.4s linear 0s;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  color: white;
  height: 100%;
  background-color: rgba(116, 169, 56, 0.85);
  transform: scale(0.9);
  /*margin: 20px;*/
  /*width: calc(100vw / 4 - 100px);*/
}
</style>
