<template>

  <div :class="this.$store.state.mtype.mtype === 'mobile'?'concat':''"> {{ this.$store.state.baseInfo.baseInfo.phone }}
    | <a :href="'mailto:'+this.$store.state.baseInfo.baseInfo.mail">{{ this.$store.state.baseInfo.baseInfo.mail }}</a>
<!--    <a :href="'mailto:'+this.$store.state.baseInfo.baseInfo.mail" class="skype"><i class="el-icon-message" style="font-size: 25px;color: white; font-weight: 400"></i></a>-->

    <a href="skype:live:849951157?chat"  class="skype">
      <i class="iconfont icon-skype"  style="font-size: 20px"></i>
<!--    <img-->
<!--        src="../assets/Skype.png"/>-->
    </a>
    <a :href="'mailto:'+this.$store.state.baseInfo.baseInfo.mail" class="skype"><i class="iconfont icon-email-fill" style="font-size: 25px;color: white; font-weight: 400"></i></a>
  </div>

</template>

<script>
import Contact from "@/pages/custom/contact";
import  "@/assets/iconfont/iconfont.css"
import  "@/assets/iconfont/iconfont.js"
export default {
  name: "headerConcat",
  components: {Contact},
  data() {
    return {
      imgshow: false,
    }
  },
  mounted() {
    // console.log(this.$store.state)
    if (this.$store.state.mtype.mtype === 'mobile') {
      this.imgshow = true
    }

  }

}
</script>

<style scoped>

div {
  /*width: 100vw;*/
  background-color: #74a938;
  line-height: 31px;
  color: white;
  padding-left: 20px;
}

a {
  color: white;
  text-decoration: none;
  margin: 0;
  padding:0;
}

img {
  height: 20px;
}

.concat {
  position: sticky;
  top: 0;
  z-index: 1000;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/
}

.skype {
  float: right;
  margin-right: 10px;
  height: 30px;
}


</style>
