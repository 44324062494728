<template>
  <div :class="this.$store.state.mtype.mtype === 'mobile'?'navim':'navi'">
    <el-menu class="bar" :default-active="defaultActive" @select="handleSelect"
             active-text-color="#74a938" ref="navig"
             menu-trigger="click">
      <!--      <nav-menu v-for="(item) in menus" :menuitem="item"></nav-menu>-->
      <template v-for="menuitem of menus" :index="menuitem.urlto">
        <template v-if="menuitem.children.length===0 ">
          <el-menu-item :index="menuitem.urlto">
          <span>{{
              menuitem.name
            }}</span>
          </el-menu-item>
        </template>
        <template v-else>
          <el-submenu :index="menuitem.urlto" style="width: auto">
            <template v-slot:title><span>{{ menuitem.name }}</span></template>
            <el-menu-item v-for="subitem in menuitem.children" :key="subitem.name" :index="subitem.name"
            ><span>{{
                subitem.name
              }}</span>
            </el-menu-item>
          </el-submenu>
        </template>
      </template>

    </el-menu>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "sideMenu",
  data() {
    return {
      imgshow: true,
      menus1: [
        {name: "Home", urlto: "/home", children: [], orderNum: 1},
        {
          name: "Products",
          urlto: "/product",
          children: [{
            name: "USB flash drives",
            urlto: "#",
            children: [],
            orderNum: 1,
            pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
          },
            {
              name: "Power & Charging",
              urlto: "#",
              children: [],
              orderNum: 2,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Tech gifts",
              urlto: "#",
              children: [],
              orderNum: 3,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Desk & office",
              urlto: "#",
              children: [],
              orderNum: 4,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Bags & backpackse",
              urlto: "#",
              children: [],
              orderNum: 5,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Keying & lanyardse",
              urlto: "#",
              children: [],
              orderNum: 6,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Outdoor & travek",
              urlto: "#",
              children: [],
              orderNum: 7,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            },
            {
              name: "Mugs & drinkware",
              urlto: "#",
              children: [],
              orderNum: 8,
              pic: 'http://192.168.0.101:81/dev-api/profile/upload/2023/05/31/8ed8abc3cd65df633ddc49a64d9492f_20230531095033A010.jpg'
            }],
          orderNum: 2
        }, {name: "Accessories", urlto: "/accessories", children: [], orderNum: 3}, {
          name: "Packaging",
          urlto: "/packaging",
          children: [{name: "Paper packaging", urlto: "#", children: [], orderNum: 1},
            {name: "Wooden packing", urlto: "#", children: [], orderNum: 2},
            {name: "Plastic packinge", urlto: "#", children: [], orderNum: 3},
            {name: "Metal packaginge", urlto: "#", children: [], orderNum: 4},
            {name: "PU leather packing", urlto: "#", children: [], orderNum: 5},
            {name: "Bulk packing", urlto: "#", children: [], orderNum: 6},
            {name: "Fabric cloth packaging", urlto: "#", children: [], orderNum: 7},],
          orderNum: 4
        }, {name: "About", urlto: "/about", children: [], orderNum: 5}, {
          name: "Contact",
          urlto: "/contact",
          children: [],
          orderNum: 6
        }],
      pmenus: []
    };
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.menus = this.$store.state.menu.menu
  //     // console.log("menu", this.menus)
  //     this.menus = this.$store.state.menu.menu.filter(x => !(x.name === "About" || x.name === "Contact"))
  //   })
  // }
  // ,


  methods: {

    handleSelect(key, keyPath) {
      // console.log(key, keyPath[1]);
      this.$router.push({path: keyPath[0], query: {categories: keyPath[1]}})
    },

  },
  computed: {
    menus() {
      // this.pmenus = this.menus.filter(x => !(x.name === "About" || x.name === "Contact"))
      let menu = this.$store.state.menu.menu

      menu = menu.filter(x => !(x.name === "About" || x.name === "Contact" || x.name === "Home"))

      return menu
    },
    defaultActive() {
      var index = this.$route.path

      if (this.$route.query.categories) {
        index = this.$route.query.categories
      }
      if (index === "/") {
        index = "/home"
      }
      return index
    }

  }
}
</script>

<style scoped>

.navi {
  position: sticky;
  top: 0;
  z-index: 1000;
  /*width: 100vw;*/
  /*height: 80px;*/
  /*background-color: #67b7e1;*/
  line-height: 80px;
  /*color: white;*/
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  /*flex-wrap:wrap*/
  /*width: 100vw;*/
}
.navim {
  position: sticky;
  top: 31px;
  z-index: 1000;
  /*width: 100vw;*/
  /*height: 80px;*/
  /*background-color: #67b7e1;*/
  line-height: 80px;
  /*color: white;*/
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  /*flex-wrap:wrap*/
  /*width: 100vw;*/
}
.el-menu{
  border: none;
  width:100%;
  /*border: black solid 2px;*/
}
/*.el-menu:nth-child(n){*/
/*  height: 10px !important;*/
/*}*/

/*.bar {*/
/*  !*width: 100vw;*!*/
/*  !*background-color: coral;*!*/
/*  transform: scale(1);*/
/*  display: flex;*/
/*}*/

.pic {
  padding-left: 20px;
  padding-top: 20px;
  height: 60px;
}

/*.bar :nth-child(-n+5){*/
/*  width: 10%;*/
/*}*/

div img {
  height: 100%;
  /*padding-left: 20px;*/
  /*padding-top: 20px;*/
  /*!*height: 4vw;*!*/
  /*height: 60px;*/
}

/*a {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*}*/
@media screen and (max-width: 768px) {
  /*.bar {*/
  /*  transform: scale(0.88);*/
  /*  transform-origin: 0 0 0;*/
  /*  !*transform: scale(0.8);*!*/
  /*  !*background-color: #000000 ;*!*/
  /*}*/

  .navim {
    display: block;
    /*width: 100vw!important;*/
  }


  ul {
    margin-block-start: 0;
  }

}
</style>
<style>
/*!*.el-submenu {*!*/
/*!*  border: black solid 2px;*!*/
/*!*  width: auto !important;*!*/
/*!*}*!*/
/*.el-submenu__title {*/
/*  border: red solid 2px;*/
/*  width: auto !important;*/
/*}*/
/*.el-menu-item, .el-submenu{*/
/*  !*height: 40px !important;*!*/
/*  !*line-height: auto;*!*/
/*  font-size: 10px;*/
/*  border: green solid 3px;*/
/*}*/

/*!*.el-menu-item{*!*/
/*!*  width: 20% !important;*!*/
/*!*}*!*/
/*!*.el-submenu {*!*/
/*!*  width: auto !important;*!*/
/*!*}*!*/

/*!*.el-submenu__title {*!*/
/*!*  width: auto !important;*!*/
/*!*}*!*/

</style>
