export default {
    state: {
        baseInfo: {},

    },
    mutations: {
        //修改
        setBaseInfo(state, p) {
            // console.log("123",menus)
            state.baseInfo = p
        }
    },

}
