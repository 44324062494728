export default {
    state: {
        mtype: "",

    },
    mutations: {
        //修改
        getmType(state, p) {
            // console.log("123",menus)
            state.mtype = p
           }
    },

}
