<template>
  <div class="footer">
    <el-row>
      <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
        <div>
          <h2>WHY CHOOSE US</h2>
          <li class="footd">First-hand prices directly from 200+ manufactures</li>
          <li class="footd">Personal customer advice</li>
          <li class="footd">High quality products</li>
          <li class="footd">100% QC checked before departure</li>
          <li class="footd">Fast delivery time</li>
          <li class="footd">Fast after-sale service</li>
          <li class="footd">5 years warranty</li>
          <li class="footd">CE/RoHS certified</li>

        </div>
      </el-col>
      <el-col :xs="0" :sm="6" :md="6" :lg="6" :xl="6">
        <h2>PRODUCTS</h2>
        <div class="footd" v-for="(item,index) in categoriesList" :key="index" @click="toProduct(item.name)">
          <a>{{ item.name }}</a></div>


      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
        <h2>CONNECT</h2>
        <p> Phone:{{ this.$store.state.baseInfo.baseInfo.phone }}</p>
        <p> Email: <a :href="'mailto:'+this.$store.state.baseInfo.baseInfo.mail">{{
            this.$store.state.baseInfo.baseInfo.mail
          }}</a></p>
        <p> Address: {{ this.$store.state.baseInfo.baseInfo.address }}</p>

        <img v-if="this.$store.state.mtype.mtype!=='mobile'"
             :src="this.$store.state.baseInfo.baseInfo.logo"/>
      </el-col>
      <!--      <el-col :span="6">-->
      <!--        <img src="../assets/logo.png"/>-->
      <!--&lt;!&ndash;        <h2>EXPLORE</h2>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p>>Product Support</p>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p> >Warranty Registration</p>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p> >Retail Store</p>&ndash;&gt;-->
      <!--&lt;!&ndash;        <p> >Blog</p>&ndash;&gt;-->
      <!--      </el-col>-->
    </el-row>
  </div>
</template>

<script>
export default {
  name: "footerArea",
  data() {
    return {
      categoriesList1:[]
    }
  },
  mounted() {
    // this.$nextTick(() => this.categoriesList = this.$store.state.menu.products)

  },
  methods: {
    toProduct(categories) {
      // this.$router.push(`/product?categories=${categories}`)
      this.$router.push({path: '/product', query: {categories: categories}})
      // this.$route.push(`/products?categories=${categories}`)
      // this.$route.push("/products")
    }
  },
  computed: {
    categoriesList() {
      return this.$store.state.menu.products
    },}

}
</script>

<style scoped>

.footer {
  background-color: #50586226;
  color: #505862;
  /*margin: 30px 0 0 0;*/
}

.el-col {
  /*width: 25%;*/
  padding-left: 5%;
  padding-right: 2%;;
}

a {
  cursor: pointer;
  color: #505862;
  text-decoration: none;
}

img {
  /*padding: 40px 0;*/
  width: 15vw;
  height: auto;
}

.footd {
  padding-top: 5px;
}


li {
  padding: 0;
  margin: 0;
  line-height: 20px;
  white-space: nowrap;
  /*color: grey;*/
}

ul {
  padding: 0;
}

p {
  line-height: 20px;
}

</style>
