<template>

  <div>
    <header-concat></header-concat>
    <header-navigate></header-navigate>
    <router-view></router-view>
    <footer-area></footer-area>
    <!--    <el-container>-->
    <!--      <el-header>-->
    <!--        <header-concat></header-concat>-->

    <!--      </el-header>-->
    <!--      <el-main>-->
    <!--        <header-navigate></header-navigate>-->
    <!--        <banner></banner>-->
    <!--        <categories></categories>-->
    <!--      </el-main>-->
    <!--      <el-footer>-->
    <!--        <footer-area></footer-area>-->
    <!--      </el-footer>-->
    <!--    </el-container>-->
  </div>
</template>

<script>
import axios from "axios"
import headerConcat from "@/components/headerConcat";
import headerNavigate from "@/components/headerNavigate";
import footerArea from "@/components/footer";

export default {
  components: {headerConcat, headerNavigate, footerArea},
  data() {
    return {
      feets: [],
      filterfeets: [],
      years: null,
      locs: []
    };
  },
  mounted() {
    this.getTerminalType()
    this.getBaseInfo()
    // this.$nextTick(() => this.getMenus())
    // this.getMenus()
    // this.$router.push("/cusHome")
    // this.getlocs()
    // this.getallfeet()
  }
  ,
  methods: {
    getTerminalType() {
      // console.log(navigator.userAgent)
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.$store.commit("getmType", 'mobile')
        return 'mobile';
      } else if (/(Android)/i.test(navigator.userAgent)) {
        this.$store.commit("getmType", 'mobile')
        return 'mobile';
      } else {
        this.$store.commit("getmType", 'pc')
        return 'pc';
      }
    },
    getBaseInfo() {
      axios.get(this.api.baseUrl + '/open/api/info').then(res => {
        // console.log(res.data.data)
        this.$store.commit('setBaseInfo', res.data.data)

        // console.log(this.feets)
        // this.$ref.geoChart.setfdata(this.feets)
      }).catch(err => {
        // console.log("获取数据失败" + err);
      })
    },

    getMenus() {
      // console.log('before',this.$router.getRoutes())
      this.$store.dispatch('GetMenu').then(
          x => {
            // console.log("xxxxxxxxxxx",x)
            x.forEach(x => {
              this.$router.addRoute('main', x)
            })
          }
      )
      // console.log('after',this.$router.getRoutes())
      //   axios.get(this.api.baseUrl + '/open/api/classifymenu').then(res => {
      //   if (res.data.code === 200) {
      //     // console.log(res.data.data.aboutpics)
      //     // this.menus = res.data.data
      //     this.$store.commit("refreshMenu", res.data.data)
      //     console.log('before',this.$router.getRoutes())
      //     this.$store.commit('addMenu',this.$router)
      //
      //
      //   } else {
      //     throw 'err'
      //   }
      // })
    },
    filters() {
      // alert(this.years)
      if (this.years === "" || this.years === null) {
        this.filterfeets = this.feets
      } else {
        this.filterfeets = this.feets.filter(item =>
            item.stime.substring(0, 4) === this.years
        )
      }
    },
    getallfeet() {
      axios.get(this.api.baseUrl + '/allfeet').then(res => {
        this.feets = res.data
        this.filterfeets = this.feets
        // console.log(this.feets)
        // this.$ref.geoChart.setfdata(this.feets)
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    },
    getlocs() {
      axios.get(this.api.baseUrl + '/getloc').then(res => {
        this.locs = res.data
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    }

  }
}
</script>
<style scoped>

/*.el-header {*/
/*  !*position: sticky;*!*/
/*  !*top: 0;*!*/
/*  !*width: 100%;*!*/
/*  !*height: 100%;*!*/
/*  !*text-align: center;*!*/
/*  height: auto !important;*/
/*  background-color: rgba(251, 252, 252, 1);*/
/*  padding: 0;*/
/*  z-index: 1000;*/
/*}*/
/*.el-footer {*/
/*  padding: 0;*/
/*}*/

/*.el-main .navi{*/
/*  position: sticky;*/
/*  top: 0;*/
/*}*/

/*.el-main {*/
/*  height: 500px;*/
/*}*/
</style>
