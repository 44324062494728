import Vue from 'vue'
import Vuex from "vuex";
import menu from "./custom/menu"
import mtype from "@/store/custom/mtype";
import baseInfo from "@/store/custom/baseInfo";

Vue.use(Vuex)

//创建Vuex实例
export default new Vuex.Store({

        modules: {
            menu, mtype,baseInfo
        }
    }
)
