import axios from "axios";
import api from "@/api";
export default {
    state: {
        menu: [],
        products: [],
    },
    mutations: {
        //修改
        // refreshMenu(state, menus) {
        //     // console.log("123",menus.filter(x =>
        //     //     x.name === "Products"
        //     // ))
        //
        //     state.menu = menus
        //     state.products = menus.filter(x =>
        //         x.name === "Products"
        //     )[0].children
        //
        // },
        // //动态注册路由
        // addMenu(state, router) {
        //     var menuArray = []
        //     state.menu.forEach(x => {
        //         var a = {}
        //         if (x.name === 'Home' || x.name === 'About' || x.name === 'Contact') {
        //             a.component = () => import(`../../pages/custom${x.urlto}`)
        //         } else {
        //             a.component = () => import("../../pages/custom/product")
        //         }
        //         // x.component = () => import(`../../pages/custom${x.urlto}`)
        //         a.path = x.urlto ? x.urlto : '#'
        //         a.name = x.name
        //         // console.log(x)
        //         menuArray.push(a)
        //
        //     })
        //
        //     //路由动态添加
        //     // console.log(menuArray)
        //     menuArray.forEach(x => {
        //         router.addRoute('main', x)
        //     })
        //
        // },
        SET_MENU: (state, menu) => {

            state.menu = menu;
            // console.log('shezhi l menbu',menu)
            state.products =  state.menu.filter(x =>
                x.name === "Products"
            )[0].children
            // 设置Storage存储
            // setStore({
            //     name: 'menu',
            //     content: menu
            // });
        },

    },
    actions: {
        // 获取系统菜单
        GetMenu({ commit }, obj) {
            return new Promise((resolve) => {
                axios.get(api.baseUrl + '/open/api/classifymenu').then((res) => {
                    // const menu = res.data.data;
                    // 可以将拿到的数据做个深拷贝
                    // const menu = deepClone(data);
                    commit('SET_MENU',  res.data.data);
                    var menuArray = []
                    res.data.data.forEach(x => {
                        var a = {}
                        if (x.name === 'Home' || x.name === 'About' || x.name === 'Contact') {
                            a.component = () => import(`../../pages/custom${x.urlto}`)
                        } else {
                            a.component = () => import("../../pages/custom/product")
                        }
                        // x.component = () => import(`../../pages/custom${x.urlto}`)
                        a.path = x.urlto ? x.urlto : '#'
                        a.name = x.name
                        // console.log(a)
                        menuArray.push(a)

                    })
                    resolve(menuArray);
                });
            });
        },
    }

}
