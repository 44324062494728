<template>
  <div style="text-align: center">
    <div style="height: 18vh">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="出发城市">
              <el-input autosize v-model="form.ifcity" placeholder="请输入出发城市"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="到达城市">
              <el-input autosize v-model="form.itcity" placeholder="请输入到达城市"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出行日期">
              <el-date-picker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="form.istime"
                              style="width: 100%;"></el-date-picker>
              <!--          <el-input autosize v-model="form.istime" placeholder="请输入出行日期"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出发方式">
              <el-select v-model="form.itakeby" placeholder="选择出发方式">
                <el-option label="飞机" value="飞机"></el-option>
                <el-option label="火车" value="火车"></el-option>
                <el-option label="汽车" value="汽车"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button @click="onClear">重置</el-button>
        </el-form-item>
      </el-form>


    </div>
    <div>

      <el-table :data="feets"
                stripe
                :cell-style="cellStyle"
                :default-sort="{prop: 'stime', order: 'descending'}"
                height="80vh">
        <el-table-column
            type="index"
            sortable
            label="序号"
            width="150">
        </el-table-column>

        <el-table-column
            prop="fcity"
            sortable
            label="出发城市"
        >
        </el-table-column>
        <el-table-column
            prop="tcity"
            sortable
            label="到达城市"
        >
        </el-table-column>
        <el-table-column
            prop="stime"
            sortable
            label="出行日期"
            :formatter="formatterTime">
        </el-table-column>
        <el-table-column
            prop="takeby"
            sortable
            label="出行方式"
        >
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "backManage",
  data() {
    return {
      feets: [],
      form: {},
      ifcity: "",
      itcity: "",
      istime: "",
      itakeby: ""
    };
  },
  mounted() {
    this.getallfeet()
  }
  ,
  methods: {
    formatterTime(row, column) {
      let data = row[column.property]
      // console.log(data)
      return data.substring(0, 10)
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      if (row[column.property] === '飞机') {
        return "font-weight:700;color:#a6c84c ";
      } else if (row[column.property] === '汽车') {
        return "font-weight:700;color:#46bee9 ";
      } else if (row[column.property] === '火车') {
        return "font-weight:700;color:#ffa022 ";
      } else
        return "";
    },
    onSubmit() {
      // console.log(this.form)

      const params = {
        lon: null,
        lat: null,
        tcity: this.form.itcity,
        fcity: this.form.ifcity,
        takeby: this.form.itakeby,
        stime: moment(this.form.istime).format('YYYY-MM-DD')
      }
      this.addfeet(params)
    },
    onClear() {
      this.form = {}
    },
    get() {
      axios.get(this.api.baseUrl + '/sql').then(res => {
        // console.log(res.data);
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    },
    getallfeet() {
      axios.get(this.api.baseUrl + '/allfeet').then(res => {
        this.feets = res.data
        // console.log(this.feets)
        // this.$ref.geoChart.setfdata(this.feets)
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    },
    del() {    //删除操作
      axios.get(this.api.baseUrl + '/delfeet', {
        params: {
          id: 100000
        }
      }).then(res => {
        // console.log(res.data);
        if (res.data.status == 200) {
          this.all()
        } else {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        }
      }).catch(err => {
        console.log("操作失败" + err);
      })
    },
    addfeet(params) {   //添加操作
      axios.get(this.api.baseUrl + '/getlon', {
        params: {n: params.fcity}
      })
      axios.get(this.api.baseUrl + '/getlon', {
        params: {n: params.tcity}
      })
      axios.get(this.api.baseUrl + '/addfeet', {
        params: params
      }).then(res => {
        // console.log(res.data);
        if (res.data.status == 200) {
          this.$alert
          this.getallfeet()
        } else {
          this.$message({
            message: '添加失败',
            type: 'error'
          });
        }
      }).catch(err => {
        console.log("操作失败" + err);
      })
    }
  }
}
</script>

<style scoped>

</style>
