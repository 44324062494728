<template>
  <div class="block">
    <!--    <span class="demonstration">默认 Hover 指示器触发</span>-->
    <el-carousel :height="this.$store.state.mtype.mtype==='mobile'?'25vw':'25vw'" ref="slideCarousel">
      <el-carousel-item v-for="(item , index) in banners" :key="index">
        <el-image
            class="banner"
            style="width: 100%; height: 100%"
            :src=" item.pic"
            fit="cover"
            @click="navTo(item.url)"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "banner",
  data() {
    return {
      banners: [{
        pic: "https://powerstick.com/main/wp-content/uploads/2022/12/SILKEN_kit_animation_slider_v4_12.22.22.gif",
        url: "#"
      },
        {pic: "https://powerstick.com/main/wp-content/uploads/2023/04/EcoSpot_Pro_homepage_slider_lrg_0418.2023.jpg"},
        {pic: "https://powerstick.com/main/wp-content/uploads/2023/02/Puzzle200pcs_homepage_slider_lrg_02.23.23.gif"}]
    }
  },
  mounted() {
    // console.log("bannermounted",this.$store.state)
    this.$nextTick(()=>{this.getBanner()})

    this.slideBanner()
  },
  methods: {
    navTo(uri) {
      this.$router.push(uri)
      // console.log(uri)
    },
    getBanner(){
      axios.get(this.api.baseUrl + '/open/api/banner').then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data.aboutpics)
          this.banners = res.data.data
          // this.banners.forEach(x=> x.pic = this.$store.state.baseInfo.baseInfo.domain + x.pic)
          // console.log(this.banners)
        } else {
          throw 'err'
        }
      })
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPointX = 0
      var stopPointX = 0
      //重置坐标
      var resetPoint = function () {
        startPointX = 0
        stopPointX = 0
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint()
          return
        }
        if (startPointX - stopPointX > 0) {
          resetPoint()
          that.$refs.slideCarousel.next()
          return
        }
        if (startPointX - stopPointX < 0) {
          resetPoint()
          that.$refs.slideCarousel.prev()
          return
        }
      });
    },
  }
}
</script>

<style scoped>
.banner {
  max-width: 100%;
  max-height: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
