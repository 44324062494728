import Vue from 'vue';
// import { createApp } from 'vue'
import App from './App.vue'
import echarts from 'echarts'
import router from './router/index'
import Element from 'element-ui'
import api from './api'
import store from "@/store";
import localCarousel from './components/index'
import VueLazyload from 'vue-lazyload'
// import moment from "moment";
Vue.prototype.$echarts = echarts;
import 'element-ui/lib/theme-chalk/index.css';//使用
Vue.use(Element);
Vue.use(localCarousel)
Vue.use(VueLazyload)
Vue.prototype.api = api

// Vue.use(moment)
// App.use(Router)
new Vue({
    router,
    store,
    render: (h) => h(App),
    // created() {
    //     store.commit('addMenu',router)
    // }
}).$mount('#app');

